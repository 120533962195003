import React, { useState } from 'react';
import * as Yup from 'yup';
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import { Formik, Form } from 'formik';
import { AlertModal } from './components/alertmodal';
import Button from './components/Button';
import DateInput from './components/date-picker';
import ButtonDropdown from './components/button-dropdown';
import InputGrp from './components/input-grp/input-grp';
import { ReasonModal } from './components/reason-modal';
import ToolTip from './components/tooltip';
import ChoiceSelector from './components/yes-no-selector';
import TextArea from './components/text-area/text-area';
import WithLabelExample from './components/progress-bar';
import ErrorBoundary from './components/errorboundary';
import QuantityInput from './components/quantity-input';
import { ScrollToError } from './components/ScrollToError';
import SelectElement from './components/select-element';
import PercentageSelector from './components/percentage-selector';
import HeatmapRange from './components/heatmap-range';
import Loader from './components/loader';
import TabsComponent from './components/tabs-component';

export default function App() {
  const [isLoading, setIsLoading] = useState(true);

  // Simulate loading for demonstration purposes
  setTimeout(() => {
    setIsLoading(false); // Hide the loader after 3 seconds
  }, 3000);
  const handleLogoClick = () => {
    console.log('Logo clicked!');
  };
  // tab 
  const handleTabChange = (key: string | null) => {
    console.log('Active Tab:', key);
  };
  const handleLogout = () => {
    console.log('User logged out');
  };

  const handleSettingsClick = () => {
    console.log('Settings clicked!');
  };

  const breadcrumbs = [
    { label: 'Aeroport' },
    { label: 'Dashboard', isActive: true },
  ];

  const buttons = [
    {
      id: 'dashboard',
      label: 'Power BI Dashboard',
      iconClass: 'icon icon-pie-chart',
      tooltipText: 'Go to Power BI dashboard',
      onClick: () => console.log('Navigating to Power BI Dashboard'),
      selectedTab: 'dashboard',
    },
    {
      id: 'my-task',
      label: 'My Task',
      iconClass: 'icon icon-article',
      tooltipText: 'My task',
      onClick: () => console.log('Navigating to My Task'),
      selectedTab: 'my-task',
    },
    {
      id: 'home',
      label: 'Home',
      iconClass: 'iconHome',
      tooltipText: 'Home',
      onClick: () => console.log('Navigating to home'),
      selectedTab: 'home',
      btnCustomClass: 'homeBtnWrapper',
    },
  ];
  
  const innerSidebarMenu = [
    { id: 'rfq', label: 'RFQ' },
    { id: 'bga', label: 'BGA' },
    { id: 'pg1', label: 'PG1' },
    { id: 'nomination', label: 'Nomination' },
  ];

 const [isModalVisible, setIsModalVisible] = useState(false);

const handleShowModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handlePositiveAction = () => {
    console.log('Positive action confirmed');
    setIsModalVisible(false);
  };

  const handleNegativeAction = () => {
    console.log('Negative action confirmed');
    setIsModalVisible(false);
  };

const btnHandleClick = () => {
    alert('Button clicked!');
  };

const handleDropdownClick = (selectedOption: string) => {
  console.log('Selected Option:', selectedOption);
};

const initialValues = {
  date: '',
  standaloneInput: '',
  exampleChoice: 'option1',
  reason: '',
  comments: '',
  percentage: '',
};

const validationSchema = Yup.object().shape({
  date: Yup.string().required('Date is required'),
  standaloneInput: Yup.string().required('Input is required'),
  exampleChoice: Yup.string().required('Please select an option'),
  reason: Yup.string().required('Reason is required'),
  comments: Yup.string().required('Comments are required'),
  percentage: Yup.string().required('Percentage is required'),

});
const [rangeValue, setRangeValue] = useState(5);

const handleRangeChange = (value: number) => {
  setRangeValue(value);
  console.log('Selected Range Value:', value);
};
const handleSubmit = (values: any) => {
  console.log('Form values:', values);
};

  return (
    <>
  {isLoading && (
        <Loader
          visible={isLoading}
          color="#b7c9e7"
          autoHideDelay={3000} // Optional: Change if you need a different auto-hide delay
          styles={{ justifyContent: 'center', alignItems: 'center', height: '100vh' }} // Optional: Custom styles
        />
      )}
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ setFieldValue, values, errors }) => (
        <Form>
          <Header  
            logoClick={handleLogoClick}
            logoUrl="/images/logo.svg"
            logoAlt="SKF Logo"
            breadcrumbs={breadcrumbs}
            isProduction={true}
            onLogout={handleLogout}
            onSettingsClick={handleSettingsClick}
            navbarExpand="md"
            navbarVariant="dark"
            navbarClassName="justify-content-between header-nav"
            containerClassName="container container--large"
          />
          
          <div className='d-flex'>
            <Sidebar
              stageClickHandler={() => {}}
              buttons={buttons}
              innerSidebarMenu={innerSidebarMenu}
            />
            
            <div className='d-flex flex-column gap-4 w-75'>
              <div>  
                <button type="button" onClick={handleShowModal}>Show Modal</button>
                <AlertModal
                  show={isModalVisible}
                  headerText="Confirmation"
                  bodyText="Are you sure you want to proceed?"
                  showNegative={true}
                  negativeText="No"
                  positiveText="Yes"
                  onNegative={handleNegativeAction}
                  onPositive={handlePositiveAction}
                  modalSize="sm"
                />
              </div>

              <div>
                <Button buttonName="Click Me" className='btn-outline-primary' />
                <Button buttonName="Submit" onClick={btnHandleClick} className='btn-outline-secondary' />
                <Button buttonName="Disabled" disabled={true} className='btn-outline-secondary'/>
                <Button buttonName="Styled Button" className="btn-primary" />
              </div>

              <DateInput
                name="date"
                label="Select a Date"
                placeHolder="MM/DD/YYYY"
                minDate="2023-01-01"
                maxDate="2025-12-31"
                dateFormat="yyyy/MM/dd"
              />

              <ButtonDropdown
                buttonOptions={['Option 1', 'Option 2', 'Option 3']}
                onClick={handleDropdownClick}
                disabled={false}
                defaultOption='Option 2'
                buttonClassName='my-custom-button-class'
                dropdownClassName='my-custom-dropdown-class'
                iconSrc='/images/add.svg'
                dropdownIconSrc='/images/arrow-white-icon.svg'
                iconSize={20}
              />

              <InputGrp
                name="standaloneInput"
                placeHolder="Enter something"
                WrapperClassName="my-3"
                showIcon={true}
                handleChange={({ selectedValue }: any) => setFieldValue('standaloneInput', selectedValue)}
              />

              <ReasonModal
                show={true}
                headerText="Confirm Action"
                positiveText="Submit"
                initialValues={{ reason: '' }}
                validationSchema={Yup.object().shape({
                  reason: Yup.string().required('Reason is required'),
                })}
                formFields={[{ name: 'reason', type: 'text', placeholder: 'Enter reason' }]}
                onPositive={handlePositiveAction}
              />

              <ToolTip
                tooltipText="This is a tooltip"
                placement="right"
                trigger={['hover', 'focus']} // Specify triggers as an array
                showIcon={true}
                iconSrc="/images/error_outline.svg"
                iconAlt="Error Icon"
                iconHeight={20}
                iconWidth={20}
                iconClassName="my-icon-class"
              />

              <ChoiceSelector
                name="exampleChoice"
                label="Choose an Option"
                dataSets={[
                  { value: 'option1', label: 'Option 1' },
                  { value: 'option2', label: 'Option 2' },
                ]}
                handleChange={(values, selectedValue, setFieldValue) => {
                  setFieldValue('exampleChoice', selectedValue);
                }}
                handleBlur={(value) => {
                  console.log('Blurred:', value);
                }}
                isExempt={{ fields: [{ field: 'exampleChoice' }] }}
              />

              <TextArea
                name="comments"
                placeHolder="Enter your comments"
                cols={5}
                WrapperClassName="my-3"
                isDisabled={false}
              />
             <ErrorBoundary>
                <WithLabelExample percentage={75} />
                <QuantityInput
                name="quantity"
                label="Quantity"
                placeHolder="Enter Quantity"
                minimum={0}
                disable={{ field: 'someField', value: 'someValue' }} // Example disable condition
              />
              </ErrorBoundary>
              <ScrollToError
        bodyText="Please correct the errors in the form before proceeding."
        positiveText="Okay"
        negativeText="Close"
        showNegative={false}
        onPositive={() => console.log('Error acknowledged')}
      />
<SelectElement
name="yourFieldName"
placeholder="Select an option"
options={[
{ label: 'Option 1', value: '1' },
{ label: 'Option 2', value: '2' },
]}
labelKey="label"
valueKey="value"
customClass="your-custom-class"
handleChange={(value) => console.log(value)}
/>
    <PercentageSelector
                name="percentage"
                label="Select Percentage"
                dataSets={[
                  { value: '25', label: '25%' },
                  { value: '50', label: '50%' },
                  { value: '75', label: '75%' },
                  { value: '100', label: '100%' },
                ]}
                handleChange={(value) => setFieldValue('percentage', value)}
                handleBlur={(value) => console.log('Blurred:', value)}
                isDisabled={false}
                formGroupClassName="my-3"
                OptionClassName="my-option-class"
                value={values.percentage}
                error={errors.percentage}
              />

                
            <HeatmapRange
                name="heatmapRange"
                label="Select Range"
                value={rangeValue.toString()}
                handleChange={(value) => setRangeValue(value)}
                onRangeChange={handleRangeChange}
                isDisabled={false}
                min={0}
                max={10}
                step={1}
              />
              <p>Current Range Value: {rangeValue}</p>

              <TabsComponent
      defaultTabKey="home"
      tabs={[
        { tabKey: 'home', title: 'Home', content: <div>Home Content</div> },
        { tabKey: 'profile', title: 'Profile', content: <div>Profile Content</div> },
        { tabKey: 'settings', title: 'Settings', content: <div>Settings Content</div> },
        { tabKey: 'comments', title: 'Comments', content: <div>Comments Content</div>, isDisabled: true },
      ]}
      onTabChange={handleTabChange}
    />
              <button type="submit">Submit All</button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
    
    </>
  );
}
