import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import './heatmap-rang.scss';

interface Props {
  name: string;
  label?: string;
  isDisabled?: boolean;
  value?: string;
  error?: string | undefined | boolean;
  handleChange: (value: any) => void;
  handleBlur?: (value: any) => void;
  min?: number;
  max?: number;
  step?: number;
  onRangeChange?: (value: number) => void;
  arrowDownIconPath?: string;
  arrowUpIconPath?: string;
}

const HeatmapRange: React.FC<Props> = ({
  name,
  label = '',
  value,
  error,
  handleChange,
  handleBlur,
  isDisabled = false,
  min = 0,
  max = 10,
  step = 1,
  onRangeChange,
  arrowDownIconPath = '/images/arrow-down.svg',
  arrowUpIconPath = '/images/arrow-up.svg',
}) => {
  const [rangeVal, setRangeVal] = useState(0);

  const { values, errors, setFieldValue, touched }: any = useFormikContext();

  useEffect(() => {
    const root = document.documentElement;
    root?.style.setProperty(
      '--range-width',
      `${rangeVal > max * 10 ? max * 10 : rangeVal}%`
    );
  }, [rangeVal, max]);

  useEffect(() => {
    if (values[name]) {
      const val = isNaN(parseInt(values[name])) ? 0 : values[name];
      setRangeVal(val * 10);
      if (onRangeChange) onRangeChange(val);
    }
  }, [values, name, onRangeChange]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = Number(e.currentTarget.value || 0);
    setRangeVal(val);
    setFieldValue(name, val);
    if (onRangeChange) onRangeChange(val);
  };

  return (
    <div>
      <Form.Group>
        <div className={`rangeWrapper`}>
          <Form.Range
            onInput={handleInputChange}
            value={isNaN(parseInt(values[name])) ? 0 : values[name]}
            min={min}
            max={max}
            step={step}
            disabled={isDisabled}
            name={name}
          />
          <div className={`rangeThumb`}>
            <div className={`thumbIndicator`}>
              <img
                src={arrowDownIconPath}
                alt='arrow-down'
                width={20}
                height={8}
              />
            </div>

            <small className={`thumbValue`}>
              {rangeVal <= max * 10 ? (rangeVal / 10).toFixed(2) : max}
            </small>

            <div className={`thumbIndicator`}>
              <img
                src={arrowUpIconPath}
                alt='arrow-up'
                width={20}
                height={8}
              />
            </div>
          </div>
        </div>
        {touched[name] && errors[name] && (
          <small className='text-danger text-sm'>{errors[name]}</small>
        )}
      </Form.Group>
    </div>
  );
};

export default HeatmapRange;
