import React from 'react';
import { useFormikContext } from 'formik';
import  { useEffect, useState, } from 'react';
import { Form } from 'react-bootstrap';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';

interface Option {
  label: string;
  value: string;
}

interface Props {
  name: string;
  label?: string;
  isDisabled?: boolean;
  formGroupClassName?: string;
  OptionClassName?: string;
  dataSets: Option[];
  value?: string;
  error?: string | undefined | boolean;
  handleChange: (value: string) => void;
  handleBlur?: (value: string) => void;
}

function PercentageSelector(props: Props) {
  const {
    name,
    label = '',
    formGroupClassName = '',
    OptionClassName = '',
    value,
    handleChange,
    handleBlur,
    isDisabled,
    dataSets,
  } = props;

  const [radioValue, setRadioValue] = useState('');
  const [percentageOpts, setOpts] = useState<Option[]>([
    { label: '25%', value: '25' },
    { label: '50%', value: '50' },
    { label: '75%', value: '75' },
    { label: '100%', value: '100' },
  ]);

  useEffect(() => {
    if (value) {
      setRadioValue(value);
    }
  }, [value]);

  useEffect(() => {
    if (dataSets.length) {
      setOpts(dataSets);
    }
  }, [dataSets]);

  const { values, errors, setFieldValue, touched } = useFormikContext<any>();

  // Cast errors[name] to string if possible or handle it gracefully
  const errorMessage = typeof errors[name] === 'string' ? errors[name] : '';

  return (
    <Form.Group className={formGroupClassName}>
      {label && <Form.Label>{label}</Form.Label>}
      <ButtonGroup className='choiceWrapper'>
        {percentageOpts.map((radio, idx) => (
          <ToggleButton
            key={idx}
            id={`radio-${idx}`}
            type='radio'
            variant='outline-primary'
            name='radio'
            disabled={isDisabled}
            value={radio.value}
            checked={values[name] && values[name] === radio.value}
            onChange={(e) => {
              const selectedValue = e.currentTarget.value;
              setRadioValue(selectedValue);
              handleChange(selectedValue);
              setFieldValue(name, selectedValue);
            }}
            className={OptionClassName}
          >
            {radio.label}
          </ToggleButton>
        ))}
      </ButtonGroup>
      {touched[name] && errorMessage && (
        <small className='text-danger'>{errorMessage}</small>
      )}
    </Form.Group>
  );
}

export default PercentageSelector;
