import { Field, useFormikContext } from 'formik';
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

interface TextAreaProps {
  name: string;
  placeHolder?: string;
  WrapperClassName?: string;
  isDisabled?: boolean;
  cols?: number;
  rows?: number;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  className?: string;
  style?: React.CSSProperties;
}

const TextArea = ({
  name,
  isDisabled = false,
  placeHolder = 'Field Text',
  cols = 4,
  rows = 2,
  onChange,
  onBlur,
  className = '',
  style = {},
  WrapperClassName = '',
  ...props
}: TextAreaProps) => {
  const { values, errors, setFieldValue, touched }: any = useFormikContext();
  const [fieldValue, setValue] = useState(values[name] || '');

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.currentTarget.value);
    setFieldValue(name, e.currentTarget.value);
    if (onChange) onChange(e);
  };

  const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    if (onBlur) onBlur(e);
  };

  return (
    <Form.Group className={`${WrapperClassName}`}>
      <div className="text-area-group">
        <Field
          as="textarea"
          name={name}
          value={fieldValue}
          className={`form-control text-area ${className}`}
          placeholder={placeHolder}
          disabled={isDisabled}
          cols={cols}
          rows={rows}
          onChange={handleChange}
          onBlur={handleBlur}
          style={style}
          aria-label={placeHolder}
          {...props}
        />
      </div>
      {touched[name] && errors[name] && (
        <small className="text-danger">{errors[name]}</small>
      )}
    </Form.Group>
  );
};

export default TextArea;
