// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabs_wrap {
  width: 50%;
}
.tabs_wrap :global li.nav-item {
  flex: 1;
}
.tabs_wrap :global li.nav-item:focus-visible {
  outline: none;
}
.tabs_wrap :global li.nav-item button.nav-link {
  width: 100%;
  color: rgb(39, 51, 66);
  border-radius: 0px;
  border-bottom: 0px;
}
.tabs_wrap :global li.nav-item button.nav-link.active {
  border-top: 3px solid #0f58d6;
  border-radius: 0px;
  color: #0f58d6;
  font-weight: 600;
}
.tabs_wrap :global li.nav-item button.nav-link:hover, .tabs_wrap :global li.nav-item button.nav-link:focus {
  border-bottom: none;
}
.tabs_wrap :global li.nav-item button.nav-link:focus-visible {
  box-shadow: unset;
}

.tabInnerWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  max-height: 100%;
  height: calc(100vh - 250px);
  overflow-y: scroll;
}
.tabInnerWrapper::-webkit-scrollbar {
  width: 2.5px;
}
.tabInnerWrapper::-webkit-scrollbar-track {
  background: #e9e3e3;
  border-radius: 50%;
}
.tabInnerWrapper::-webkit-scrollbar-thumb {
  background-color: var(--primary-600);
  border-radius: 50%;
  background-clip: border-box;
}`, "",{"version":3,"sources":["webpack://./src/components/tabs-component/tabs-component.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;AACF;AACE;EACE,OAAA;AACJ;AAAI;EACE,aAAA;AAEN;AAAI;EACE,WAAA;EACA,sBAAA;EACA,kBAAA;EACA,kBAAA;AAEN;AADM;EACE,6BAAA;EACA,kBAAA;EACA,cAAA;EACA,gBAAA;AAGR;AADM;EAEE,mBAAA;AAER;AAAM;EACE,iBAAA;AAER;;AAKA;EACE,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,kBAAA;EACA,gBAAA;EACA,2BAAA;EACA,kBAAA;AAFF;AAGE;EACE,YAAA;AADJ;AAIE;EACE,mBAAA;EACA,kBAAA;AAFJ;AAKE;EACE,oCAAA;EACA,kBAAA;EACA,2BAAA;AAHJ","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
