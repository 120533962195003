import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { AlertModal } from '../alertmodal';

interface ScrollToErrorProps {
  bodyText?: string;
  positiveText?: string;
  negativeText?: string;
  onPositive?: () => void;
  onNegative?: () => void;
  showNegative?: boolean;
}

export function ScrollToError({
  bodyText = 'Please fill all the mandatory fields to proceed.',
  positiveText = 'Okay',
  negativeText = 'Close',
  onPositive,
  onNegative,
  showNegative = true,
}: ScrollToErrorProps) {
  const formik = useFormikContext();
  const submitting = formik?.isSubmitting;
  const isValid = formik?.isValid;

  const [showDiscardModal, setShowDiscardModal] = useState(false);

  useEffect(() => {
    if (!isValid) {
      setShowDiscardModal(true);
    }
  }, [submitting, isValid]);

  const handlePositive = () => {
    setShowDiscardModal(false);
    onPositive && onPositive();
  };

  const handleNegative = () => {
    setShowDiscardModal(false);
    onNegative && onNegative();
  };

  return (
    <AlertModal
      show={showDiscardModal}
      bodyText={bodyText}
      positiveText={positiveText}
      negativeText={negativeText}
      showNegative={showNegative}
      onPositive={handlePositive}
      onNegative={handleNegative}
    />
  );
}
