import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';

interface SelectElementProps {
  options?: Array<{ id?: number; label: string; value: any }>;
  labelKey?: string;
  valueKey?: string;
  placeholder?: string;
  name: string;
  multiple?: boolean;
  handleChange?: (value: any) => void;
  error?: string;
  touched?: boolean;
  customClass?: string;
}

const SelectElement: React.FC<SelectElementProps> = ({
  placeholder = 'Type or select',
  name,
  multiple = false,
  error = '',
  options = [],
  labelKey = 'label',
  valueKey = 'value',
  customClass = '',
  ...props
}) => {
  const { values, errors, setFieldValue, touched }: any = useFormikContext();

  const [dropdownOptions, setOptions] = useState<any[]>(options);

  useEffect(() => {
    setOptions(options.length ? options : defaultOptions);
  }, [options]);

  const defaultOptions = [
    { id: 1, label: 'Option 1', value: 'option 1' },
    { id: 2, label: 'Option 2', value: 'option 2' },
  ];

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = multiple
      ? Array.from(event.target.selectedOptions, (option) => option.value)
      : event.target.value;

    setFieldValue(name, selectedValue);

    if (props.handleChange) {
      props.handleChange(selectedValue);
    }
  };

  return (
    <div className={customClass}>
      <Form.Select
        size="sm"
        name={name}
        value={values[name]}
        onChange={handleSelectChange}
        multiple={multiple}
        {...props} // Pass through additional props
      >
        <option value="" disabled>
          {placeholder}
        </option>
        {dropdownOptions.map((option, index) => (
          <option key={option.id || index} value={option[valueKey]}>
            {option[labelKey]}
          </option>
        ))}
      </Form.Select>
      {touched[name] && errors[name] && (
        <small className="error text-danger">{errors[name]}</small>
      )}
    </div>
  );
};

export default SelectElement;
