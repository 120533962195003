// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rangeWrapper {
  background: linear-gradient(90deg, #27c940 0%, #ffca15 50.83%, #cc0000 100%);
  height: 40px;
  width: 100%;
  position: relative;
  border-radius: 5px;
}
.rangeWrapper input[type=range] {
  opacity: 0;
  z-index: 2;
  position: inherit;
  height: 100%;
}
.rangeWrapper .rangeThumb {
  position: absolute;
  top: 0;
  height: 100%;
  width: var(--range-width);
  text-align: end;
  z-index: 1;
  padding: 0 2px;
}
.rangeWrapper .rangeThumb .thumbIndicator {
  height: 6px;
}
.rangeWrapper .rangeThumb .thumbIndicator:first-of-type img {
  margin-top: -17px;
}
.rangeWrapper .rangeThumb .thumbIndicator:last-of-type img {
  margin-top: -20px;
}
.rangeWrapper .rangeThumb .thumbValue {
  font-family: Arial;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/components/heatmap-range/heatmap-rang.scss"],"names":[],"mappings":"AAAA;EACE,4EAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;EACA,kBAAA;AACF;AAAE;EACE,UAAA;EACA,UAAA;EACA,iBAAA;EACA,YAAA;AAEJ;AAAE;EACE,kBAAA;EACA,MAAA;EACA,YAAA;EACA,yBAAA;EACA,eAAA;EACA,UAAA;EACA,cAAA;AAEJ;AADI;EACE,WAAA;AAGN;AAFM;EACE,iBAAA;AAIR;AAFM;EACE,iBAAA;AAIR;AADI;EACE,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;EACA,WAAA;AAGN","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
