import React, { useEffect, useState } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';

type Props = {
  percentage: number;
  label?: boolean;
  variant?: 'success' | 'info' | 'warning' | 'danger';
  striped?: boolean;
  animated?: boolean;
};

function WithLabelExample({
  percentage,
  label = true,
  variant = 'info',
  striped = false,
  animated = false,
}: Props) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (typeof percentage === 'number' && percentage >= 0 && percentage <= 100) {
      setProgress(percentage);
    } else {
      console.error('Invalid percentage value:', percentage);
    }
  }, [percentage]);

  return (
    <ProgressBar
      now={progress}
      label={label ? `${progress}%` : undefined}
      variant={variant}
      striped={striped}
      animated={animated}
    />
  );
}

export default WithLabelExample;
