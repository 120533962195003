import { useFormikContext } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

interface Props {
  name: string;
  label?: string;
  isDisabled?: boolean;
  placeHolder?: string;
  WrapperClassName?: string;
  datePickerClassName?: string;
  value?: string;
  error?: string | undefined | boolean;
  handleChange?: (
    selectedValue: string,
    updateTimeStatus: (field: string, value: string) => void
  ) => void;
  handleBlur?: (value: any) => void;
  showInfo?: string;
  updateTimeStatus?: (field: string, value: string) => void;
  minDate?: Date | string;
  maxDate?: Date | string;
  dateFormat?: string;
  showMonthDropdown?: boolean;
  showYearDropdown?: boolean;
  yearDropdownItemNumber?: number;
  scrollableYearDropdown?: boolean;
}

const DateInput = ({
  name,
  label = '',
  placeHolder = 'Select date',
  WrapperClassName = '',
  datePickerClassName = '',
  value,
  error,
  handleChange,
  handleBlur,
  updateTimeStatus,
  isDisabled = false,
  minDate,
  maxDate,
  dateFormat = 'yyyy/MM/DD',
  showMonthDropdown = true,
  showYearDropdown = true,
  yearDropdownItemNumber = 50,
  scrollableYearDropdown = true,
}: Props) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const { values, errors, setFieldValue, touched }: any = useFormikContext();

  useEffect(() => {
    if (values[name]) {
      setStartDate(new Date(moment(values[name], 'YYYY-MM-DD').format('MM/DD/YYYY')));
    } else if (value) {
      setStartDate(new Date(moment(value, 'YYYY-MM-DD').format('MM/DD/YYYY')));
    } else {
      setStartDate(new Date());
    }
  }, [values, value]);

  const handleDateChange = (date: Date | null) => {
    if (date) {
      const formattedDate = moment(date).format('YYYY-MM-DD');
      setStartDate(date);
      if (updateTimeStatus && handleChange) {
        handleChange(formattedDate, updateTimeStatus);
      }
      setFieldValue(name, formattedDate);
    }
  };

  return (
    <>
      <Form.Group
        className={`${WrapperClassName} datepicker-div border-gray-500 ${
          isDisabled ? 'datepicker-disabled' : ''
        }`}
      >
        {label && <Form.Label>{label}</Form.Label>}
        <DatePicker
          name={name}
          minDate={minDate ? new Date(minDate) : undefined}
          maxDate={maxDate ? new Date(maxDate) : undefined}
          selected={startDate}
          onChange={handleDateChange}
          placeholderText={placeHolder}
          disabled={isDisabled}
          className={datePickerClassName}
          dateFormat={dateFormat}
          disabledKeyboardNavigation={false}
          showMonthDropdown={showMonthDropdown}
          showYearDropdown={showYearDropdown}
          dropdownMode="select"
          scrollableYearDropdown={scrollableYearDropdown}
          yearDropdownItemNumber={yearDropdownItemNumber}
          autoComplete="off"
          onKeyDown={(e) => e.preventDefault()}
        />
      </Form.Group>
      {touched[name] && errors[name] && (
        <small className="text-danger">{errors[name]}</small>
      )}
    </>
  );
};

export default DateInput;
