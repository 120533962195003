import { Field, useFormikContext } from 'formik';
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

interface InputGrpProps {
  name: string;
  placeHolder?: string;
  WrapperClassName?: string;
  isDisabled?: boolean;
  showIcon?: boolean;
  updateMap?: any;
  updateValues?: { field: string };
  handleChange?: (args: {
    allValues: any;
    selectedValue: any;
    setFieldValue: any;
  }) => void;
}

const InputGrp = ({
  name = '',
  isDisabled = false,
  showIcon = true,
  placeHolder = 'Field Text',
  ...props
}: InputGrpProps) => {
  const { values, errors, setFieldValue, touched }: any = useFormikContext();
  const [fieldValue, setValue] = useState('');
  return (
    <Form.Group className={`${props.WrapperClassName || ''}`}>
      <div className='input-group'>
        <Field
          type='text'
          name={name}
          className='form-control'
          value={values[name]}
          placeholder={placeHolder}
          aria-label={placeHolder}
          disabled={isDisabled}
          onChange={(e: any) => {
            setValue(e.currentTarget.value);
            setFieldValue(name, e.currentTarget.value);
            if (props.updateMap && props.updateValues) {
              const { update, type } =
                props.updateMap[props.updateValues.field];
              if (type == 'update-only') {
                const val = update(e.currentTarget.value);
                if (val) {
                  setFieldValue(props.updateValues.field, val);
                }
              }
            }
            if (props.handleChange) {
              props.handleChange({
                allValues: values,
                selectedValue: e.currentTarget.value,
                setFieldValue,
              });
            }
          }}
        />
        {showIcon && (
          <span className='input-group-text'>
            <span className='icon icon-published_with_changes'></span>
          </span>
        )}
      </div>
      {/* <small className='text-danger'></small> */}
      {!props.updateValues && touched[name] && errors[name] && (
        <small className='text-danger'>{errors[name]}</small>
      )}
    </Form.Group>
  );
};

export default InputGrp;
