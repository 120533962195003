import React, { useEffect, useState } from 'react';
// import { camelCase } from '../../utils/helper';
import { Button, Dropdown, DropdownButton } from 'react-bootstrap';
import './button-dropdown.scss';
// import { disabledOptions } from '../../VO/app.constants';

interface Props {
  onClick: (type: string) => void;
  buttonOptions: string[];
  disabled?: boolean;  // Make this optional with a default value
  defaultOption?: string;  // Allow setting a default selected option
  buttonClassName?: string;  // Allow custom class names for button
  dropdownClassName?: string;  // Allow custom class names for dropdown
  iconSrc?: string;  // Allow setting custom icon source
  dropdownIconSrc?: string;  // Allow setting custom dropdown icon source
  iconSize?: number;  // Allow setting custom icon size
}

const ButtonDropdown = ({
  buttonOptions,
  onClick,
  disabled = false,
  defaultOption,
  buttonClassName = '',
  dropdownClassName = '',
  iconSrc = '/images/add.svg',
  dropdownIconSrc = '/images/arrow-white-icon.svg',
  iconSize = 15,
}: Props) => {
  const [options, setOptions] = useState<string[]>([]);
  const [selectedOpt, setSelectedOpt] = useState(defaultOption || buttonOptions[0]);

  useEffect(() => {
    setOptions(buttonOptions);
    if (!defaultOption) {
      const [firstOpt] = buttonOptions;
      setSelectedOpt(firstOpt);
    }
  }, [buttonOptions, defaultOption]);

  return (
    <div className={`d-flex btnDropdown`}>
      <Button
        className={`btn align-items-center ${buttonClassName}`}
        onClick={() => onClick(selectedOpt)}
        title={selectedOpt}
        disabled={disabled}
      >
        <span>
          <img
            src={iconSrc}
            alt='button icon'
            width={iconSize}
            height={iconSize}
          />
        </span>
        {selectedOpt}
      </Button>
      <DropdownButton
        align='end'
        disabled={disabled}
        title={
          <span>
            <img
              src={dropdownIconSrc}
              alt='toggle dropdown icon'
              width={iconSize}
              height={iconSize}
            />
          </span>
        }
        id='dropdown-menu-align-end'
        onSelect={(value) => {
          if (value) {
            setSelectedOpt(value);
            onClick(value);
          }
        }}
        className={`dropdown ${dropdownClassName}`}
      >
        {options.map((option) => (
          <Dropdown.Item
            eventKey={option}
            key={option}  // Add key for React's list rendering
            disabled={disabled}
            // disabled={disabled || disabledOptions.includes(option)}
          >
            {option}
          </Dropdown.Item>
        ))}
      </DropdownButton>
    </div>
  );
};

export default ButtonDropdown;
