import { Field, Formik, Form as FormikForm, FormikHelpers } from 'formik';
import React, { ReactElement, useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';

interface Props {
  show: boolean;
  headerText?: string;
  bodyText?: string | ReactElement;
  showNegative?: boolean;
  closeButton?: boolean;
  negativeText?: string;
  positiveText: string;
  modalSize?: 'sm' | 'xl' | 'lg';
  onNegative?: () => void;
  onPositive: (values: any, helpers: FormikHelpers<any>) => void;
  initialValues: any;
  validationSchema?: any;
  formFields: Array<{ name: string; type: string; placeholder: string }>;
}

export const ReasonModal = (props: Props) => {
  const {
    show,
    headerText,
    bodyText,
    showNegative,
    negativeText,
    positiveText,
    onNegative,
    onPositive,
    modalSize,
    closeButton,
    initialValues,
    validationSchema,
    formFields,
  } = props;

  const [showModal, setShowModal] = useState(show);

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  const negativeClickHandler = () => {
    setShowModal(false);
    onNegative && onNegative();
  };

  // Helper function to render errors as strings
  const renderError = (error: any) => {
    if (typeof error === 'string') {
      return <small className="text-danger">{error}</small>;
    }
    return null;
  };

  return (
    <Modal
      size={modalSize || 'sm'}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={showModal}
      onHide={negativeClickHandler}
    >
      {headerText && (
        <Modal.Header closeButton={!!closeButton}>
          <Modal.Title>{headerText}</Modal.Title>
        </Modal.Header>
      )}
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={onPositive}
      >
        {({ errors, touched, setFieldValue, submitForm }) => (
          <>
            <Modal.Body>
              {bodyText && <div>{bodyText}</div>}
              <FormikForm>
                {formFields.map((field) => (
                  <Form.Group key={field.name}>
                    <Field
                      type={field.type}
                      name={field.name}
                      className="form-control"
                      placeholder={field.placeholder}
                      onChange={(e: any) =>
                        setFieldValue(field.name, e.currentTarget.value)
                      }
                    />
                    {touched[field.name] && renderError(errors[field.name])}
                  </Form.Group>
                ))}
              </FormikForm>
            </Modal.Body>
            <Modal.Footer>
              {showNegative && (
                <Button variant="outline-dark" onClick={negativeClickHandler}>
                  {negativeText}
                </Button>
              )}
              <Button type="submit" variant="primary" onClick={submitForm}>
                {positiveText}
              </Button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </Modal>
  );
};
