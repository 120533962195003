import React, { ReactElement, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

interface Props {
  show: boolean;
  headerText?: string;
  bodyText: string | ReactElement;
  showNegative?: boolean;
  closeButton?: boolean;
  negativeText?: string;
  positiveText: string;
  modalSize?: 'sm' | 'xl' | 'lg';
  onNegative?: () => void;
  onPositive: (type?: string) => void;
  forProgram?: boolean;
}

export const AlertModal = ({
  show,
  headerText,
  bodyText,
  showNegative = false,
  closeButton = true,
  negativeText = 'Cancel',
  positiveText,
  onNegative,
  onPositive,
  modalSize = 'sm',
  ...restProps
}: Props) => {
  const [showModal, setShowModal] = useState(show);

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  const handleNegativeClick = () => {
    setShowModal(false);
    onNegative?.();
  };

  const handlePositiveClick = () => {
    setShowModal(false);
    onPositive();
  };

  return (
    <Modal
      {...restProps}
      size={modalSize}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={showModal}
      onHide={handleNegativeClick}
    >
      {headerText && (
        <Modal.Header closeButton={closeButton}>
          <Modal.Title>{headerText}</Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body>
        {typeof bodyText === 'string' ? <p>{bodyText}</p> : bodyText}
      </Modal.Body>
      <Modal.Footer>
        {showNegative && (
          <Button variant="outline-dark" onClick={handleNegativeClick}>
            {negativeText}
          </Button>
        )}
        <Button variant="primary" onClick={handlePositiveClick}>
          {positiveText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
