import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { Form } from 'react-bootstrap';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';

interface Choice {
  value: string;
  label: string;
}

interface Props {
  name: string;
  label?: string;
  isDisabled?: boolean;
  formGroupClassName?: string;
  OptionClassName?: string;
  dataSets: Choice[];
  value?: string;
  error?: string; // Only accept string errors
  handleChange?: (values: any, selectedValue: any, setFieldValue: any) => void;
  handleBlur?: (value: any) => void;
  isExempt?: any;
}

function ChoiceSelector(props: Props) {
  const {
    name,
    label = '',
    formGroupClassName = '',
    OptionClassName = '',
    value,
    error,
    handleChange,
    handleBlur,
    isDisabled,
    dataSets,
  } = props;

  const [choiceValue, setChoiceValue] = useState<string>(value || '');
  const { values, errors, setFieldValue, touched }: any = useFormikContext();

  useEffect(() => {
    if (value) {
      setChoiceValue(value);
    }
  }, [value]);

  // Convert Formik errors to a displayable format
  const getErrorMessage = (fieldName: string): React.ReactNode => {
    if (errors[fieldName] && touched[fieldName]) {
      return typeof errors[fieldName] === 'string'
        ? errors[fieldName]
        : 'An error occurred'; // Default error message if not a string
    }
    return null;
  };

  return (
    <Form.Group className={`${formGroupClassName}`}>
      {/* Optional Label */}
      {label && <Form.Label>{label} </Form.Label>}
      <ButtonGroup className='choiceWrapper'>
        {dataSets.map((choice, idx) => (
          <ToggleButton
            key={idx}
            id={`${name}-${idx}`}
            type='radio'
            variant='outline-primary'
            name={name}
            disabled={isDisabled}
            value={choice.value}
            checked={values[name] === choice.value}
            onChange={(e) => {
              const newValue = e.currentTarget.value;
              setChoiceValue(newValue);
              handleChange && handleChange(values, newValue, setFieldValue);
              if (props.isExempt?.fields?.map((data: any) => data.field).includes(name)) {
                const currentWarnings = values['warnings'] || [];
                const index = currentWarnings.indexOf(name);
                if (index > -1) {
                  currentWarnings.splice(index, 1);
                  setFieldValue('warnings', [...currentWarnings]);
                }
              }
              setFieldValue(name, newValue);
            }}
            className={`${OptionClassName}`}
          >
            {choice.label}
          </ToggleButton>
        ))}
      </ButtonGroup>
      {error && <small className='text-danger'>{error}</small>}
      {getErrorMessage(name) && <small className='text-danger'>{getErrorMessage(name)}</small>}
    </Form.Group>
  );
}

export default ChoiceSelector;
