import React from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import './tabs-component.scss';

interface TabInnerProps {
  tabKey: string;
  title: string;
  content: React.ReactNode; // Use React.ReactNode for content to allow various types of children
  isDisabled?: boolean;
}

interface TabsProps {
  defaultTabKey: string;
  tabs: TabInnerProps[];
  onTabChange?: (key: string | null) => void; // Type the parameter to handle null values
}

const TabsComponent: React.FC<TabsProps> = ({
  defaultTabKey,
  tabs,
  onTabChange,
}) => {
  return (
    <div>
      <Tabs
        defaultActiveKey={defaultTabKey}
        className={`tabs_wrap mb-3`}
        onSelect={(key) => {
          if (onTabChange) {
            onTabChange(key);
          }
        }}
      >
        {tabs.map((tab, index) => (
          <Tab
            eventKey={tab.tabKey}
            title={tab.title}
            disabled={tab.isDisabled}
            key={index}
          >
            <div
              className={`${
                tab.tabKey === 'comments' ? 'tabInnerWrapper' : ''
              }`}
            >
              {tab.content}
            </div>
          </Tab>
        ))}
      </Tabs>
    </div>
  );
};

export default TabsComponent;
