import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import './tooltip.scss';

interface TooltipProps {
  id?: string;
  tooltipText?: React.ReactNode;
  placement?: 'top' | 'right' | 'bottom' | 'left';
  trigger?: 'click' | 'hover' | 'focus' | ('click' | 'hover' | 'focus')[];
  showIcon?: boolean;
  iconSrc?: string;
  iconAlt?: string;
  iconHeight?: number;
  iconWidth?: number;
  className?: string;
  tooltipClassName?: string;
  iconClassName?: string;
}

const ToolTip = ({
  id = 'tooltip-1',
  tooltipText = '',
  placement = 'right',
  trigger = ['hover', 'focus'], // Default to 'hover' and 'focus'
  showIcon = true,
  iconSrc = '/images/error_outline.svg',
  iconAlt = 'open tooltip',
  iconHeight = 20,
  iconWidth = 20,
  className = '',
  tooltipClassName = 'mytooltip',
  iconClassName = 'toolTip-img',
}: TooltipProps) => {
  return (
    <div className={className}>
      {showIcon && (
        <OverlayTrigger
          placement={placement}
          overlay={
            <Tooltip id={id} className={tooltipClassName}>
              {tooltipText}
            </Tooltip>
          }
          trigger={Array.isArray(trigger) ? trigger : [trigger]} // Ensure trigger is an array
        >
          <img
            src={iconSrc}
            alt={iconAlt}
            height={iconHeight}
            width={iconWidth}
            className={iconClassName}
          />
        </OverlayTrigger>
      )}
    </div>
  );
};

export default ToolTip;
