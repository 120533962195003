// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.quantity_input input::-webkit-outer-spin-button,
.quantity_input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/quantity-input/quantity-input.scss"],"names":[],"mappings":"AACE;;EAEE,wBAAA;EACA,SAAA;AAAJ","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
