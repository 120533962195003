// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toolTip-img {
  margin-left: 5px;
  margin-top: -1px;
}

.mytooltip {
  padding: 15px;
  color: var(--text-black);
  background-color: #e7eefb;
  border: 1px solid rgb(79, 132, 224);
}
.mytooltip .tooltip-inner {
  max-width: max-content;
}
.mytooltip .tooltip-inner > :not(img) {
  max-width: 350px;
}
.mytooltip .tooltip-arrow::before {
  border-right-color: #0f58d6;
}`, "",{"version":3,"sources":["webpack://./src/components/tooltip/tooltip.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,gBAAA;AACJ;;AAEE;EAEE,aAAA;EACA,wBAAA;EACA,yBAAA;EACA,mCAAA;AAAJ;AACI;EACE,sBAAA;AACN;AACM;EACE,gBAAA;AACR;AAMM;EACE,2BAAA;AAJR","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
