import React from 'react';

interface ButtonProps {
  buttonName: string;
  className?: string;
  onClick?: () => void;  // Optional click handler
  type?: 'button' | 'submit' | 'reset';  // Button type
  disabled?: boolean;  // Disabled state
}

export default function Button({
  buttonName,
  className = '',
  onClick,
  type = 'button',
  disabled = false,
}: ButtonProps) {
  return (
    <button
      type={type}
      className={`btn ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      {buttonName}
    </button>
  );
}