// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/images/home-filled.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("/public/images/home-outlined.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebaropportmenu {
  border-right: 1px solid var(--gray-300);
  width: 278px;
  max-width: 100%;
  height: 100vh;
}
.sidebaropportmenu .opportunity-tab {
  font-family: SKF Sans;
  font-size: 16px;
  line-height: 1.125;
  font-weight: 700;
  color: var(--text-black);
  position: relative;
  margin-top: 21px;
  padding-left: 43px;
  padding-bottom: 10px;
  padding-right: 15px;
  min-height: 39px;
  border-bottom: 1px solid var(--gray-300);
}
.sidebaropportmenu .opportunity-tab .icon {
  position: absolute;
  left: 12px;
  top: -3px;
  font-size: 20px;
}
.sidebaropportmenu .opportunity-stage {
  font-family: SKF Sans;
  font-size: 14px;
  line-height: 114.286%;
  border-bottom: 1px solid var(--gray-300);
  padding: 14px 40px 10px 12px;
  position: relative;
  cursor: pointer;
}
.sidebaropportmenu .opportunity-stage::after {
  content: "\\e915";
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--orange-light);
  color: var(--warning);
  width: 24px;
  height: 24px;
  position: absolute;
  right: 8px;
  top: 50%;
  border-radius: 100%;
  transform: translateY(-50%);
  font-family: "icon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.sidebaropportmenu .opportunity-stage.active__section {
  background-color: var(--primary-600);
  color: var(--white);
  font-weight: 700;
}
.sidebaropportmenu .opportunity-stage.active::after {
  content: "\\e90d";
  color: var(--success);
  background-color: var(--green-light);
}
.sidebaropportmenu .opportunity-stage.complete::after {
  content: "\\e90e";
  color: var(--success);
  background-color: var(--green-light);
}

.menu-btn span {
  font-size: 24px;
  color: var(--primary-600);
}
.menu-btn button {
  border-radius: 0px;
  display: flex;
  align-items: center;
  padding: 10px 12px;
}
.menu-btn button:hover {
  background-color: var(--primary-600);
}
.menu-btn button:hover span {
  color: var(--white);
}
.menu-btn button:active {
  border: 0;
}
.menu-btn button.btn-select {
  background-color: var(--primary-600);
}
.menu-btn .iconHome {
  display: inline-block;
  width: 24px;
  height: 24px;
}

button.burgerMenu-btn {
  position: absolute;
  top: 24px;
  left: 16px;
  background: transparent;
  border: 0;
  padding: 0;
  margin: 0;
}
button.burgerMenu-btn:focus {
  outline: none;
}
button.burgerMenu-btn:hover {
  background: transparent;
}
button.burgerMenu-btn span {
  display: block;
  width: 24px;
  height: 3px;
  margin-bottom: 5px;
  position: relative;
  background: var(--white);
  border-radius: 3px;
  z-index: 1;
  transform-origin: 3px 0px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}
button.burgerMenu-btn span:last-child {
  margin-bottom: 0px;
  transform-origin: 0% 100%;
}
button.burgerMenu-btn.burgerMenu-open span {
  opacity: 1;
  transform: rotate(45deg) translate(0px, 0px);
  background: var(--white);
}
button.burgerMenu-btn.burgerMenu-open span:nth-child(2) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}
button.burgerMenu-btn.burgerMenu-open span:last-child {
  transform: rotate(-45deg) translate(2px, -1px);
}

.side-menu-bar {
  min-height: 100vh;
  max-height: calc(100% + 120px);
  height: calc(100% + 120px);
}
.side-menu-bar.shadow-element {
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.75);
}

.page-content-inner {
  display: flex;
  align-items: flex-start;
  height: calc(100% - 68px);
}
.page-content-inner.close .page-layout__sidebar {
  width: 0;
  max-width: 0;
  opacity: 0;
}
.page-content-inner.close .page-layout__content {
  width: 100%;
  max-width: 100%;
}

.iconHome {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center;
  background-size: contain;
  width: 16px;
  height: 16px;
}

.homeBtnWrapper:hover .iconHome, .homeBtnWrapper.btn-select .iconHome {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) no-repeat center;
  background-size: contain;
}

.dashboard-sidebar {
  width: 100%;
  max-width: 165px;
  transition: 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.75);
  min-height: 100vh;
  max-height: 100%;
}
.dashboard-sidebar button {
  text-align: left;
  display: flex;
  height: 46px;
  padding: 10px 16px 10px 15px;
  align-items: center;
  gap: 12px;
  border-radius: 0px;
  align-self: stretch;
  color: var(--primary-600);
  font-weight: 700;
}
.dashboard-sidebar button span:last-child {
  font-size: 13px;
}
.dashboard-sidebar button.btn-select, .dashboard-sidebar button:hover {
  background-color: var(--primary-600);
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/components/Sidebar/sidebar.scss"],"names":[],"mappings":"AAAA;EAEE,uCAAA;EACA,YAAA;EACA,eAAA;EACA,aAAA;AAAF;AAEE;EACE,qBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,wBAAA;EACA,kBAAA;EACA,gBAAA;EACA,kBAAA;EACA,oBAAA;EACA,mBAAA;EACA,gBAAA;EACA,wCAAA;AAAJ;AAEI;EACE,kBAAA;EACA,UAAA;EACA,SAAA;EACA,eAAA;AAAN;AAGA;EACE,qBAAA;EACA,eAAA;EACA,qBAAA;EACA,wCAAA;EACA,4BAAA;EACA,kBAAA;EACA,eAAA;AADF;AAGE;EACE,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,qCAAA;EACA,qBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,UAAA;EACA,QAAA;EACA,mBAAA;EACA,2BAAA;EAEA,8BAAA;EACA,YAAA;EACA,kBAAA;EACA,mBAAA;EACA,6BAAA;EACA,oBAAA;EACA,oBAAA;EACA,cAAA;EACA,mCAAA;EACA,kCAAA;AAFJ;AAKE;EACE,oCAAA;EACA,mBAAA;EACA,gBAAA;AAHJ;AAOI;EACE,gBAAA;EACA,qBAAA;EACA,oCAAA;AALN;AAUI;EACE,gBAAA;EACA,qBAAA;EACA,oCAAA;AARN;;AAsDE;EACE,eAAA;EACA,yBAAA;AAnDJ;AAqDE;EACE,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;AAnDJ;AAoDI;EACE,oCAAA;AAlDN;AAmDM;EACE,mBAAA;AAjDR;AAoDI;EACE,SAAA;AAlDN;AAoDI;EAAc,oCAAA;AAjDlB;AAmDE;EAEE,qBAAA;EACC,WAAA;EACA,YAAA;AAlDL;;AAuDE;EACE,kBAAA;EACA,SAAA;EACA,UAAA;EACA,uBAAA;EACA,SAAA;EACA,UAAA;EACA,SAAA;AApDJ;AAqDE;EACE,aAAA;AAnDJ;AAqDE;EACE,uBAAA;AAnDJ;AAqDE;EACE,cAAA;EACA,WAAA;EACA,WAAA;EACA,kBAAA;EACA,kBAAA;EACA,wBAAA;EACA,kBAAA;EACA,UAAA;EACA,yBAAA;EACA,iIACE;AApDN;AAuDM;EACE,kBAAA;EACA,yBAAA;AArDR;AAyDM;EACE,UAAA;EACA,4CAAA;EACA,wBAAA;AAvDR;AAwDQ;EACE,UAAA;EACA,uCAAA;AAtDV;AAwDQ;EACE,8CAAA;AAtDV;;AA6DA;EACE,iBAAA;EACA,8BAAA;EACA,0BAAA;AA1DF;AA2DE;EACE,2CAAA;AAzDJ;;AA8DE;EACE,aAAA;EACA,uBAAA;EACA,yBAAA;AA3DJ;AA6DM;EACE,QAAA;EACA,YAAA;EAEA,UAAA;AA5DR;AA8DM;EACE,WAAA;EACA,eAAA;AA5DR;;AAiEA;EAEG,oEAAA;EACA,wBAAA;EACA,WAAA;EACA,YAAA;AA/DH;;AAmEA;EACC,oEAAA;EACI,wBAAA;AAhEL;;AAsEA;EAEE,WAAA;EACA,gBAAA;EACA,4BAAA;EACA,aAAA;EACA,sBAAA;EACA,2CAAA;EACA,iBAAA;EACA,gBAAA;AApEF;AAsEE;EAEE,gBAAA;EACA,aAAA;EACA,YAAA;EACA,4BAAA;EACA,mBAAA;EACA,SAAA;EACA,kBAAA;EACA,mBAAA;EACA,yBAAA;EACA,gBAAA;AArEJ;AAsEG;EACC,eAAA;AApEJ;AAuEM;EACE,oCAAA;EACA,WAAA;AArER","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
